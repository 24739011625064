<template>
  <div class="drop-element__table">
    <button v-if="editPermission" class="btn-icon" @click="showModalEditChild(child)">
      <b-icon icon="pencil"></b-icon>
      {{ $t('table.edit') }}
    </button>
    <button v-if="deletePermission" class="btn-icon" @click="showModalDeleteChild(child)">
      <b-icon icon="trash"></b-icon>
      {{ $t('table.delete') }}
    </button>
    <div class="d-flex gap-2">
      <div v-if="showId" style="width: auto">ID:{{ child.id }}</div>
      <div>{{ child.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropElementTable',
  props: {
    child: { type: Object, default: undefined },
    showId: { type: Boolean },
    editPermission: { type: Boolean },
    deletePermission: { type: Boolean },
  },
  data() {
    return {
      isEdit: false,
    };
  },
  methods: {
    showModalEditChild(data) {
      this.$emit('showModalEditChild', data);
    },
    showModalDeleteChild(data) {
      this.$emit('showModalDeleteChild', data);
    },
  },
};
</script>

<style lang="sass">
@use '~/src/assets/sass/_variables' as *

.drop-element__table
  cursor: pointer
  padding: 5px 0 5px 10px
  display: flex
  align-items: center

.drop-element__table:hover
  background-color: #fff

[data-color-scheme="dark"]
  .drop-element__table:hover
    background-color: $bg-dark
</style>


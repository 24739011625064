<template>
  <div class="accordion__item">
    <div class="d-flex align-items-center justify-content-between table-body cursor-pointer" @click="Questions = !Questions">
      <div class="accordion__title tb table-body-title d-flex gap-2" :class="Questions ? 'is-collapsed' : ''">
        <div class="d-flex" @click.stop>
          <button v-if="canEdit" class="btn-icon" @click="showModalEditParent(row.id)">
            <b-icon icon="pencil"></b-icon>
            {{ $t('table.edit') }}
          </button>
          <button v-if="canDelete" class="btn-icon" @click="showModalDeleteParent(row)">
            <b-icon icon="trash"></b-icon>
            {{ $t('table.delete') }}
          </button>
        </div>
        <div v-if="showId" style="width: auto">ID:{{ row.id }}</div>
        <div>{{ row.name }}</div>
      </div>
      <div v-if="row.agencies" class="tb table-body-title agencies">
        {{ row.agencies.map((el) => el.name).join(', ') }}
      </div>
    </div>

    <div v-if="Questions" class="accordion__content typography">
      <DropElementTable
        v-for="target in childItem"
        :key="target.id"
        :child="target"
        :show-id="showId"
        :edit-permission="canEditChild"
        :delete-permission="canDeleteChild"
        @showModalEditChild="showModalEditChild"
        @showModalDeleteChild="showModalDeleteChild"
      />
      <div v-if="canAdd" class="d-flex mt-2 ml-2">
        <b-button size="sm" @click="showModalAddChild(row)">
          {{ $t('table.add') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import DropElementTable from './DropElementTable';
export default {
  name: 'DropDownTable',
  components: { DropElementTable },
  props: {
    row: { type: Object, default: undefined },
    showId: { type: Boolean },
    editPermission: { type: String, default: undefined },
    addPermission: { type: String, default: undefined },
    deletePermission: { type: String, default: undefined },
    editInnerPermission: { type: String, default: undefined },
    deleteInnerPermission: { type: String, default: undefined },
    childItem: { type: Array, default: undefined },
  },
  data() {
    return {
      Questions: false,
    };
  },
  computed: {
    canEdit: function () {
      return this.$checkPermissions(this.editPermission);
    },
    canDelete: function () {
      return this.$checkPermissions(this.deletePermission);
    },
    canAdd: function () {
      return this.$checkPermissions(this.addPermission);
    },
    canEditChild: function () {
      return this.$checkPermissions(this.editInnerPermission);
    },
    canDeleteChild: function () {
      return this.$checkPermissions(this.deleteInnerPermission);
    },
  },
  methods: {
    showModalAddChild(data) {
      this.$emit('showModalAddChild', data);
    },
    showModalEditChild(data) {
      this.$emit('showModalEditChild', data);
    },
    showModalDeleteChild(data) {
      this.$emit('showModalDeleteChild', data);
    },
    showModalEditParent(data) {
      this.$emit('showModalEditParent', {
        data,
      });
    },
    showModalDeleteParent(data) {
      this.$emit('showModalDeleteParent', {
        data,
      });
    },
  },
};
</script>

<style lang="sass">
@use '~/src/assets/sass/_variables' as *
.accordion__title
  padding: 8px 0 8px 35px
  position: relative
  cursor: pointer
  flex-basis: 70%

.accordion__title+div.table-body-title
  flex-basis: 30%

.accordion__title:before
  content: "+"
  color: #949ebf
  position: absolute
  font-weight: 700
  top: 50%
  -webkit-transform: translateY(-50%)
  -moz-transform: translateY(-50%)
  -ms-transform: translateY(-50%)
  -o-transform: translateY(-50%)
  transform: translateY(-50%)
  left: 8px

.accordion__title.is-collapsed:before
  content: '\–'

.accordion__content:last-child
  margin-bottom: 0

.accordion__content
  background-color: #f6f6f7
  padding: 1.125rem 60px
  overflow: hidden
  font-size: 14px

.typography
  line-height: 1.7
  font-weight: 400

.typography>:last-child
  margin-bottom: 0

.typography p
  margin-top: 0
  margin-bottom: 7px

.accordion__item .tb.table-body-title.agencies
  text-overflow: ellipsis
  white-space: nowrap
  width: 30%
  overflow: hidden

[data-color-scheme="dark"]
  .accordion__content
    background-color: $border-dark
    color: hsl(222, 14%, 70%) !important
</style>

<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap border-bottom py-2">
        <VocabularyTitle :title="$t('subChannels.subchannels')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between gap-3 mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
          </div>

          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover columns-resizable">
          <thead>
            <tr>
              <th scope="col">
                <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                {{ $t('subChannels.subchannels') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="subchannelsStatus !== 'success'">
              <td><SpinnerLoader :loading="subchannelsStatus" /></td>
            </tr>
            <template v-else>
              <tr v-for="(row, index) in tableData" :key="index">
                <td>
                  <div class="d-flex w-100 flex-fill">
                    <button v-if="$checkPermissions('sub_channel.update')" class="btn-icon" @click="showModalSubchannels({ data: row.id })">
                      <b-icon icon="pencil"></b-icon>
                      {{ $t('table.edit') }}
                    </button>
                    <button v-if="$checkPermissions('sub_channel.update')" class="btn-icon" @click="showModalDeleteSubchannels({ data: row })">
                      <b-icon icon="trash"></b-icon>
                      {{ $t('table.delete') }}
                    </button>
                    {{ row.name }}
                  </div>
                </td>
              </tr>
              <tr v-if="subchannelsStatus === 'success' && !tableData.length">
                <td>
                  <div class="d-flex w-100 justify-content-center">
                    {{ $t('table.noContent') }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('sub_channel.create')" variant="primary" @click="showModalSubchannels()">
          {{ $t('subChannels.addSubchannels') }}
        </b-button>
      </div>
      <!--FOOTER-->
    </div>

    <!--MODAL ADD\EDIT ADV-->
    <b-modal
      id="add-edit-subchannels"
      ref="add-edit-subchannels"
      size="lg"
      :title="modalSubchannel.type === 'add' ? $t('subChannels.addNewSubchannels') : $t('table.editSubchannels')"
      :ok-disabled="$v.modalSubchannel.$invalid"
      :ok-title="modalSubchannel.type === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :busy="isModalBusy"
      @ok.prevent="checkIfValidAdvThenEnter"
    >
      <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidAdvThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <b-form-group :label="$t('table.writeTitle')" label-for="input-name-adv">
          <b-form-input id="input-name-adv" v-model.trim="modalSubchannel.name" :placeholder="$t('table.enterTitle')" autofocus required></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
    <!--MODAL ADD\EDIT ADV-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import DropDownTable from '../components/DropDownTable';
import errorsHandler from '@/utils/errorsHandler';
import MultiSelect from '../components/MultiSelect';
import PaginateWrapper from '../components/PaginateWrapper';
import SortArrows from '../components/SortArrows';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import CoupleSelects from '@/components/CoupleSelects.vue';
import { getSubchannels } from '@/api/apiServices';

export default {
  name: 'Subchannels',
  components: { PaginateWrapper, MultiSelect, DropDownTable, SpinnerLoader, VocabularyTitle, SearchDropdown, SearchInput, SortArrows, CoupleSelects },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
  },

  data() {
    return {
      modalSubchannel: {
        type: 'add',
        name: '',
        value: [],
        options: [],
        selectAdd: '',
        selectDelete: '',
      },
      modalBrand: {
        type: 'add',
        name: '',
        subchannel: '',
        subchannelsList: [],
        class: '',
        group: '',
        groupList: [],
      },

      tableData: [],
      paginationData: '',
      searchElements: '',
      filterPage: 1,
      selectedPerPage: '100',

      isModalBusy: false,
      isModalLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      subchannels: 'getSubchannels',
      subchannel: 'getSubchannel',
      subchannelsStatus: 'getSubchannelsStatus',
      classes: 'getClassesList',
      group: 'getGroup',
      modalEditSubchannels: 'getModalEditSubchannels',
      modalEditClasses: 'getModalEditClasses',
      brandsEdit: 'getBrandsEdit',
      brandsListWithAll: 'getBrandsListWithAll',
    }),
  },
  validations: {
    modalSubchannel: {
      name: { required },
    },
    modalBrand: {
      name: { required },
      class: { required },
      group: { required },
      subchannel: { required },
    },
  },
  watch: {
    subchannels() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('subChannels.subchannels') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getSubchannelsInfo();
  },
  destroyed() {
    this.$store.commit('clearSubchannelsList');
    this.$store.commit('clearClassesList');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_SUBCHANNELS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
      });
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },
    // get table info
    setTableData() {
      if (this.subchannels) {
        this.tableData = this.subchannels.data;
        this.paginationData = this.subchannels.pagination;
        if (this.filterPage > this.subchannels.pagination.last_page) {
          this.filterPage = this.subchannels.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getSubchannelsInfo() {
      await this.restoreFromQuery('subchannels');
      this.paramsData();
    },

    clearSubchannelField() {
      this.modalSubchannel.type = 'add';
      this.modalSubchannel.name = '';
      this.modalSubchannel.value = [];
      this.modalSubchannel.options = [];
      this.modalSubchannel.selectAdd = '';
      this.modalSubchannel.selectDelete = '';
    },

    clearBrandFields() {
      this.modalBrand.type = 'add';
      this.modalBrand.name = '';
      this.modalBrand.subchannel = '';
      this.modalBrand.subchannelsList = [];
      this.modalBrand.class = '';
      this.modalBrand.group = '';
      this.modalBrand.groupList = [];
    },

    leftHandler() {
      if (this.modalSubchannel.selectAdd?.id) {
        this.modalSubchannel.options = this.modalSubchannel.options.filter((option) => option.id !== this.modalSubchannel.selectAdd.id);
        this.modalSubchannel.value.unshift(this.modalSubchannel.selectAdd);
        this.modalSubchannel.selectAdd = '';
      }
    },
    rightHandler() {
      if (this.modalSubchannel.selectDelete?.id) {
        this.modalSubchannel.value = this.modalSubchannel.value.filter((option) => option.id !== this.modalSubchannel.selectDelete.id);
        this.modalSubchannel.options.unshift(this.modalSubchannel.selectDelete);
        this.modalSubchannel.selectDelete = '';
      }
    },

    async showModalSubchannels(data) {
      this.isModalLoading = true;
      this.clearSubchannelField();
      data ? (this.modalSubchannel.type = 'edit') : (this.modalSubchannel.type = 'add');
      this.$refs['add-edit-subchannels'].show();
      if (data) {
        await this.$store.dispatch('GET_SUBCHANNELS_ID', data.data);
        if (this.modalEditSubchannels) {
          this.modalSubchannel.name = this.modalEditSubchannels.name;
        }
      }
      this.isModalLoading = false;
    },

    async addEditSubchannels() {
      this.isModalBusy = true;
      const formData = {
        id: this.modalSubchannel.type === 'add' ? undefined : this.modalEditSubchannels?.id,
        name: this.modalSubchannel.name,
      };
      await this.$store.dispatch(this.modalSubchannel.type === 'add' ? 'POST_SUBCHANNELS' : 'PUT_SUBCHANNELS_ID', {
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.modalSubchannel.type === 'add' ? this.$i18n.t('alert.addSubchannels') : this.$i18n.t('alert.editSubchannels'),
            text: this.modalSubchannel.name,
          });
          this.$refs['add-edit-subchannels'].hide();
          this.paramsData();
          this.clearSubchannelField();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isModalBusy = false;
    },

    async showModalDeleteSubchannels(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteSubchannels')} "${data?.data?.name}"?`, {
          title: this.$i18n.t('table.deleteSubchannels'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-adv-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data?.data?.id;
            const name = data?.data?.name;
            this.$store.dispatch('DELETE_SUBCHANNELS_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteSubchannels'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    async loadSubchannelsListForBrandModal() {
      await getSubchannels({ per_page: 1500 })
        .then((res) => (this.modalBrand.subchannelsList = res.data?.data || []))
        .catch((e) => {});
    },

    async showModalAddBrand(data) {
      this.isModalLoading = true;
      this.clearBrandFields();
      this.modalBrand.type = 'add';
      this.$refs['add-edit-brand'].show();
      await Promise.all([
        !this.classes || this.classes.length < 1 ? this.$store.dispatch('GET_CLASSES', { per_page: 1000 }) : undefined,
        this.loadSubchannelsListForBrandModal(),
      ]);
      this.modalBrand.subchannel = { id: data.id, name: data.name };
      this.isModalLoading = false;
    },

    async showModalEditBrand(data) {
      this.isModalLoading = true;
      this.clearBrandFields();
      this.modalBrand.type = 'edit';
      this.$refs['add-edit-brand'].show();
      await Promise.all([
        this.$store.dispatch('GET_BRANDS_ID', data.id),
        !this.classes || this.classes.length < 1 ? this.$store.dispatch('GET_CLASSES', { per_page: 1000 }) : undefined,
        this.loadSubchannelsListForBrandModal(),
      ]);
      if (this.brandsEdit?.data) {
        this.modalBrand.name = this.brandsEdit.data?.name;
        this.modalBrand.subchannel = this.brandsEdit.data?.subchannel || '';
        this.modalBrand.class = this.brandsEdit.data?.brand_group?.brand_class || '';
        await this.loadGroupsList();
        this.modalBrand.group = this.brandsEdit.data?.brand_group || '';
      }
      this.isModalLoading = false;
    },

    async addEditBrand() {
      this.isModalBusy = true;
      const formData = {
        brand_classes_id: this.modalBrand.class?.id,
        brand_group_id: this.modalBrand.group?.id,
        subchannel_id: this.modalBrand.subchannel?.id,
        id: this.modalBrand.type === 'add' ? undefined : this.brandsEdit?.data?.id,
        name: this.modalBrand.name,
      };
      await this.$store.dispatch(this.modalBrand.type === 'add' ? 'POST_BRANDS' : 'PUT_BRANDS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalBrand.type === 'add' ? this.$i18n.t('alert.addBrand') : this.$i18n.t('alert.editBrand'),
            text: this.modalBrand.name,
          });
          this.$refs['add-edit-brand'].hide();
          this.paramsData();
          this.clearBrandFields();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },

    async loadGroupsList() {
      if (this.modalBrand.class) {
        await this.$store.dispatch('GET_CLASSES_ID', this.modalBrand.class.id);
        this.modalBrand.groupList = this.modalEditClasses?.brand_groups || [];
        if (this.modalBrand.group) this.modalBrand.group = '';
      }
    },

    async showModalDeleteBrand(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteBrand')} "${data?.name}"?`, {
          title: this.$i18n.t('table.deleteBrand'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-brand-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data?.id;
            const name = data?.name;
            this.$store.dispatch('DELETE_BRANDS_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteBrand'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    checkIfValidAdvThenEnter() {
      if (!this.$v.modalSubchannel.$invalid && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalSubchannel.type === 'add'
              ? `${this.$i18n.t('subChannels.confirmAddSubchannels')} "${this.modalSubchannel.name}"?`
              : `${this.$i18n.t('subChannels.confirmEditSubchannels')} "${this.modalSubchannel.name}"?`,
            {
              title: this.modalSubchannel.type === 'add' ? this.$i18n.t('subChannels.addNewSubchannels') : this.$i18n.t('subChannels.editSubchannels'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-add-edit-adv-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditSubchannels();
          })
          .catch((err) => {});
      }
    },

    checkIfValidGroupThenEnter() {
      if (!this.$v.modalBrand.$invalid && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalBrand.type === 'add'
              ? `${this.$i18n.t('table.confirmAddBrand')} "${this.modalBrand.name}"?`
              : `${this.$i18n.t('table.confirmEditBrand')} "${this.modalBrand.name}"?`,
            {
              title: this.modalBrand.type === 'add' ? this.$i18n.t('table.addBrand') : this.$i18n.t('table.editBrand'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-add-edit-brand-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditBrand();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="d-flex justify-content-between">
    <select v-model="selectAdd" size="5" class="custom-select" data-automatic="available-list">
      <option v-for="option in addOptions" :key="option.id" :value="option">{{ option.name }}</option>
    </select>
    <div class="py-4">
      <button class="arrow-btn" type="button" data-automatic="add-btn" :disabled="!addElement" @click="$emit('leftHandler')">
        <b-icon class="add-icon__left" font-scale="4" icon="arrow-right"></b-icon>
      </button>
      <button class="arrow-btn" type="button" data-automatic="remove-btn" :disabled="!selectDelete" @click="$emit('rightHandler')">
        <b-icon class="add-icon__right" font-scale="4" icon="arrow-left"></b-icon>
      </button>
    </div>
    <select v-model="selectDelete" size="5" class="custom-select" data-automatic="selected-list">
      <option v-for="v in removeOptions" :key="v.id" :value="v">{{ v.name }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'CoupleSelects',
  props: {
    addOptions: {
      type: Array,
      default: undefined,
    },
    removeOptions: {
      type: Array,
      default: undefined,
    },
    //String is used for old code for empty variables
    addElement: {
      type: [Object, String],
      default: undefined,
    },
    deleteElement: {
      type: [Object, String],
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {
    selectAdd: {
      get() {
        return this.addElement;
      },
      set(value) {
        this.$emit('update:addElement', value);
      },
    },
    selectDelete: {
      get() {
        return this.deleteElement;
      },
      set(value) {
        this.$emit('update:deleteElement', value);
      },
    },
  },
};
</script>